import React, { useContext, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Layout, ImageGallery, ProductQuantityAdder } from 'components';
import { Grid, KokoWrapper, KokoSizeWrapper, PriceWrapper } from './styles';
import CartContext from 'context/CartContext';
import { navigate, useLocation } from '@reach/router';
//extract query from the url
import queryString from 'query-string';

function ProductTemplate(props) {
  const { getProductById } = useContext(CartContext);
  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const { search, origin, pathname } = useLocation();

  console.log('from useLocation', search, origin, pathname);
  console.log('selectedVariant', selectedVariant);

  //get the variant id from the url
  const variantId = queryString.parse(search).variant;
  console.log('variantId', variantId);

  console.log('selectecVariant', selectedVariant);

  useEffect(() => {
    getProductById(props.data.shopifyProduct.storefrontId).then(result => {
      setProduct(result);
      setSelectedVariant(
        result?.variants.find(({ id }) => id === variantId) ||
          result?.variants[0]
      );
    });
  }, [
    getProductById,
    setProduct,
    props.data.shopifyProduct.storefrontId,
    variantId,
  ]);

  const handleVariantSelect = e => {
    const variant_id = e.target.getAttribute('data-variant_id');
    const newVariant = product.variants.find(v => v.id === variant_id);
    setSelectedVariant(newVariant);
    //create variant url - and encode the id
    navigate(
      `${origin}${pathname}?variant=${encodeURIComponent(newVariant.id)}`,
      {
        replace: true,
      }
    );
  };
  return (
    <Layout>
      <Grid>
        <div>
          <h1>{props.data.shopifyProduct.title}</h1>
          {!!selectedVariant && (
            <>
              <PriceWrapper>{selectedVariant?.price} €</PriceWrapper>
              <ProductQuantityAdder
                available={selectedVariant.available}
                variantId={selectedVariant.id}
              />
            </>
          )}
          {product?.availableForSale && (
            <KokoWrapper>
              <strong>Koko</strong>
              <br />
              {product?.variants.map(v => (
                <KokoSizeWrapper
                  key={v.id}
                  data-variant_id={v.id}
                  onClick={handleVariantSelect}
                  selected={v.id === selectedVariant?.id}
                >
                  {v.title}
                </KokoSizeWrapper>
              ))}
            </KokoWrapper>
          )}
          {!product?.availableForSale && (
            <KokoWrapper>
              <strong>Koko - NA</strong>
              <br />
              {product?.variants.map(v => (
                <KokoSizeWrapper key={v.id}>{v.title}</KokoSizeWrapper>
              ))}
            </KokoWrapper>
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: props.data.shopifyProduct.descriptionHtml,
            }}
          ></div>
        </div>
        <div>
          <ImageGallery
            images={props.data.shopifyProduct.images}
            alt={props.data.shopifyProduct.title}
          />
        </div>
      </Grid>
    </Layout>
  );
}

export default ProductTemplate;

// GraphQL query for this page
export const query = graphql`
  query ProductQuery($productId: String) {
    shopifyProduct(storefrontId: { eq: $productId }) {
      storefrontId
      title
      description
      descriptionHtml
      images {
        gatsbyImageData(placeholder: "blurred")
        id
      }
    }
  }
`;
