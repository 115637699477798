import styled from 'styled-components';

export const Grid = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;

    > div:first-child {
      order: 2;
    }
    > div:last-child {
      order: 1;
    }
  }
`;

export const KokoWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const KokoSizeWrapper = styled.span`
  display: inline-block;
  margin: 0 10px;
  border: 4px solid ${props => (props.selected ? 'blue' : '#eee')};
`;

export const PriceWrapper = styled.div`
  margin: 10px 0;
`;
